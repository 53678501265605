import './MessageAppHeader.css';
import { FaAngleLeft } from 'react-icons/fa';
import { IoIosArrowBack } from "react-icons/io";

const CenterFrame = ({ picture, fullName,profilePictureStateChange }) => {

    return (
        <div className='MAH-Center-Frame Hover-Pointer' onClick={event => {profilePictureStateChange(event)}}>
            <div className='MAHCF-Profile-Pic-Frame'>
                <img src={picture} alt="Headshot" className='MAHCF-Profile-Pic'/>
            </div>
            <div className='MAHCF-Profile-Name-Frame'>
                {fullName}
            </div>
        </div>
    );
};


function MessageAppHeader( { height, picture, fullName, backButtonStateChange, profilePictureStateChange } ) {
    const MessageAppHeaderCSS = {
        height: height,
    };
    
    return (
        <div className='Message-App-Header' style={MessageAppHeaderCSS}>

            <div className='MAH-Back-Frame Hover-Pointer' onClick={event => {backButtonStateChange(event)}}>
                <IoIosArrowBack className='MAH-Back-Symbol'/>
            </div>

            <CenterFrame picture={picture} fullName={fullName} profilePictureStateChange={profilePictureStateChange}/>
        </div>
    );
};

export default MessageAppHeader;