import './Mobile.css'

import { useState, useEffect } from 'react'

//States
import Introduction from './Introduction/Introduction.js'
import MessageApp from './MessageApp/MessageApp'
import ContactApp from './ContactApp/ContactApp'
import ResumeApp from './ResumeApp/ResumeApp'
import HomeApp from './HomeApp/HomeApp'
import PhotoApp from './PhotoApp/PhotoApp';

// Back-End Communication
import ApiCalls from './ApiCalls/ApiCalls';
import FileSaver from 'file-saver';

// React Icons
import {IoIosHome, IoMdContact, IoIosDocument} from "react-icons/io";
import {RiMessage2Fill} from "react-icons/ri";
import {AiFillPicture} from "react-icons/ai";

// Images
import headshot from './ref/headshot.jpg';
import message from './ref/Message.svg';
import pictures from './ref/Pictures.svg';
import facebook from './ref/Facebook.png';
import linkedIn from './ref/LinkedIn.png';
import pinterest from './ref/Pinterest.png';
import contact from './ref/Contacts.svg';
import resume from './ref/Resume.svg';
import bitcoin from './ref/Bitcoin.svg';

import photo1 from './ref/photo/photo1.jpg';
import photo2 from './ref/photo/photo2.jpg';
import photo3 from './ref/photo/photo3.jpg';
import photo4 from './ref/photo/photo4.jpg';
import photo6 from './ref/photo/photo6.jpg';
import photo7 from './ref/photo/photo7.jpg';
import photo8 from './ref/photo/photo8.jpg';



function Mobile({ usersName, nameUser, changeStateTo, state, inputState, markVisited, alreadyVisited, isMobile}) {  
    const [ storedMessageList, setStoredMessageList] = useState([]);
    const [ questionList, setQuestionList ] = useState(null);
    const [ myName, setMyName ] = useState("");
    const [ myFullName, setMyFullName ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ phoneNumber, setPhoneNumber ] = useState("");
    const [ resumeObject, setResumeObject ] = useState({});

    const getResumeObject = async () => {
      ApiCalls.getResume().then( response => {
        setResumeObject(() => response.data);
      });
    };

    const getContactCard = async() => {
      ApiCalls.getContactCard()
        .then( response => {
          console.log(response.data)
          var contactFile = new Blob(
            [response.data], { type: "text/vcard;charset=utf-8" }
          );
          FileSaver.saveAs(contactFile, "kyleBostelman.vcf");
        });
    };

    const getContactData = async() => {
      ApiCalls.getContact().then( response => {
        setPhoneNumber(() => response.data.phoneNumber);
        setEmail(() => response.data.email);
      });
    };

    const getName = async() => {
      ApiCalls.getYourName().then( response => {
        setMyName(() => response.data.myName);
        setMyFullName(() => response.data.myFullName);
      });
    };

    const getQuestionList = async() => {
      ApiCalls.getQuestionList().then( response => {
        setQuestionList(() => response.data);
      });
    };

    useEffect(() => {
      getQuestionList();
      getName();
      getContactData();
      getResumeObject();
    },[])


  
    const introChatList = [
      "Welcome to my web application.",
      "Now that you know my name, it seems only fair I get yours.",
      "What would you prefer me to call you?",
    ];
  
    const revisitChatList = [
      "Welcome back!",
      "I still haven't gotten your name yet. What would you like me to call you?",
    ];
  
    const NamedRevisitChatList = [
      "Welcome back, "+ usersName,
      "If there is anything else you want to know about me, just ask.", 
      "Otherwise feel free to use the toolbar below for quick access to other sections of the application.",
    ];
  
    const IntroductionProps = {
      introductionText: "Hi, I'm Kyle",
      secondaryText: "Let's Get to Know Each Other",
      nextState: 'Contact',
      changeStateTo: changeStateTo,
      markVisited:markVisited,
    };
  
    const linkBarLinks = {
      'Message':message,
      'Photo':pictures,
      'Resume':resume,
      'Contact':contact,
    };
  
    const LinkBarProps = {
      linkBarLinks:linkBarLinks,
      changeStateTo:changeStateTo,
    };

    const inputBarLinks = {
      'Home':IoIosHome,
      'Message':RiMessage2Fill,
      'Photo':AiFillPicture,
      'Resume':IoIosDocument,
      'Contact':IoMdContact,
    };
    
    const inputBarProps = {
      inputBarLinks:inputBarLinks,
      changeStateTo:changeStateTo,
    };

    const handleResumeClick = () => {
     changeStateTo(() => "Resume");
    };
 
    const handlePhotoGallaryClick = () => {
     changeStateTo(() => "Photo");
    };

    const handleContactClick = () => {
     changeStateTo(() => "Contact");
    };

    const PhotosFolderProps = [
      {
        'icon':pictures,
        'name':'photogallary',
        'click':handlePhotoGallaryClick,
      },
    ];

    const documentsFolderProps = [
      {
        'icon':resume,
        'name':'Resume',
        'click': handleResumeClick,
      },
      {
        'icon':bitcoin,
        'name':'White Paper',
        'click': () => window.location.href = "https://bitcoin.org/bitcoin.pdf",
      }
    ];

    const contactFolderProps = [
      {
        'icon':contact,
        'name':'My Contact',
        'click': handleContactClick,
      }
    ];
    
    const LinksFolderProps = [
      {
        'icon':facebook,
        'name':"Facebook",
        'click': () => window.location.href = "http://www.facebook.com/kyle.bostelman",
      },
      {
        'icon':linkedIn,
        'name':"LinkedIn",
        'click':() => window.location.href = "https://www.linkedin.com/in/kyle-bostelman-19237213b/",
      },
      {
        'icon':pinterest,
        'name':"Pinterest",
        'click':() => window.location.href = "https://www.pinterest.com/kylebostelman/",
      }
    ];
  
    const AppHomeFolderList = {
      "Photos":PhotosFolderProps,
      "Documents":documentsFolderProps,
      "Contact":contactFolderProps,
      "Links":LinksFolderProps,
    };
    
    const HomeAppPrimaryProps = {
      LinkBarProps:LinkBarProps,
      AppHomeFolderList:AppHomeFolderList,
    };
  
    const HomeAppProps = {
      HomeAppPrimaryProps:HomeAppPrimaryProps,
    };
  
    const MessageAppProps = {
      stateName: "Message",
      picture: headshot,
      fullName: myFullName,
      questionList:questionList,
      introChatList:introChatList,
      revisitChatList:revisitChatList,
      NamedRevisitChatList:NamedRevisitChatList,
      alreadyVisited:alreadyVisited,
      inputState:inputState,
      changeStateTo: changeStateTo,
      nameUser:nameUser,
      usersName:usersName,
      inputBarProps:inputBarProps,
      storedMessageList:storedMessageList,
      setStoredMessageList:setStoredMessageList,
    };
  
    const ContactAppProps = {
      stateName:"Contact",
      LinkBarProps:inputBarProps,
      phoneNumber: phoneNumber,
      email: email,
      picture: headshot,
      fullName: myFullName,
      IoIosDocument:IoIosDocument,
      getContactCard:getContactCard,

    };
  
    const ResumeAppProps = {
      stateName: "Resume",
      LinkBarProps:inputBarProps,
      resumeObject: resumeObject,
      isMobile:isMobile,
    };

    const photoList = [
      {
        image:photo1,
        alt:'',
        proportion:'',
        delay:1,
      },
      {
        image:photo2,
        alt:'',
        proportion:'',
        delay:5,
      },
      {
        image:photo3,
        alt:'',
        proportion:'',
        delay:9,
      },
      {
        image:photo4,
        alt:'',
        proportion:'',
        delay:12,
      },
      {
        image:photo6,
        alt:'',
        proportion:'',
        delay:15,
      },      
      {
        image:photo7,
        alt:'',
        proportion:'',
        delay:19,
      },      
      {
        image:photo8,
        alt:'',
        proportion:'',
        delay:22,
      }
    ];
  
    const PhotoAppProps = {
      stateName:"Photo",
      LinkBarProps:inputBarProps,
      photoList:photoList,
    };
        
    return (
      <>
      { state &&
        <div className='Mobile-Frame'>
            {state === 'Intro' ? < Introduction {...IntroductionProps}/> : null}
            {state === 'Home' ? < HomeApp {...HomeAppProps}/> : null}
            {state === 'Message' ? < MessageApp {...MessageAppProps}/> : null}
            {state === 'Contact' ? < ContactApp {...ContactAppProps}/> : null}
            {state === 'Photo' ? < PhotoApp {...PhotoAppProps}/> : null}
            {state === 'Resume' ? < ResumeApp {...ResumeAppProps}/> : null}
        </div>
      }
      </>

    )
}

export default Mobile