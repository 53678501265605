import './Introduction.css';
import Typewriter from 'typewriter-effect';

function Introduction({introductionText,myName,secondaryText, nextState ,changeStateTo, markVisited}) {

    return (
    <div className='Intro-Primary-Window'>

        <Typewriter
            onInit={(typewriter) => {

            typewriter.typeString(introductionText)
            typewriter.typeString("\n")
            typewriter.typeString(myName)

            .pauseFor(1200)

            .deleteAll()

            .pauseFor(1000)

            .callFunction(() => {
                markVisited();
                changeStateTo(nextState);
            })

            .start();
            }}
        />
    </div>
    )
}

export default Introduction