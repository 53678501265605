import './AppHomeFolder.css';

import { useEffect, useState } from 'react';

function AppHomeFolder({folderName, iconList, openFolder}) {

  const [colorOne, setColorOne ] = useState('0');
  const [colorTwo, setColorTwo ] = useState('0');
  const [colorThree, setColorThree ] = useState('0');
  const [ alphaOne, setAlphaOne ] = useState('0');

  const folderCssProps = []
  folderCssProps['--numberOne'] = colorOne;
  folderCssProps['--numberTwo'] = colorTwo;
  folderCssProps['--numberThree'] = colorThree;
  folderCssProps['--alphaOne'] = alphaOne;

  function randomNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const changeBorderColor = () => {
    setColorOne(String(randomNumberInRange(0,255)))
    setColorTwo(String(randomNumberInRange(0,255)))
    setColorThree(String(randomNumberInRange(0,255)))
    setAlphaOne(String(randomNumberInRange(0.2,0.3)))
  }

  useEffect(() => {
    const borderTimer = setInterval(() => {
      changeBorderColor();
    }, 500);

    return () => {clearInterval(borderTimer)}
  },[])


  const handleFolderClick = () => {
    openFolder(folderName, iconList);
  }

  return (
    <div className='AH-C-Folder-Container Hover-Pointer'>
      <div className='AH-C-Folder' style={folderCssProps} onClick={event => {handleFolderClick()}}>
        {
          iconList.map((element, id) => {
            return (
              <div key={folderName+id} className='AH-C-Folder-Icon'>
                <img src={element.icon} className='AH-C-Folder-Icon-Image'/>
              </div>
            )
          })
        }
      </div>
      <label>{folderName}</label>
    </div>

  )
}

export default AppHomeFolder