import './PhotoApp.css';
import FooterBar from '../FooterBar/FooterBar';

function PhotoApp({stateName,LinkBarProps,photoList}) {

  return (
    <div className='Photo-App-Frame'>
        <div className='PA-Primary'>
          { photoList &&
            photoList.map(( element, index ) => {
              return (
                <div key={index}>
                  <img key={index} src={element.image} className='PA-Primary-Image'/>
                </div>
              )
            })
          }

        </div>
        <FooterBar linkBarList={LinkBarProps.inputBarLinks} handleLinkClick={LinkBarProps.changeStateTo} exclude={stateName}/>
    </div>
  )
}

export default PhotoApp