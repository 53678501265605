import './ContactApp.css'
import FooterBar from '../FooterBar/FooterBar';
import ContactAppHeader from './ContactAppHeader/ContactAppHeader';
import ContactAppBody from './ContactAppBody/ContactAppBody';


function ContactApp({stateName,LinkBarProps,picture,fullName,IoIosDocument, getContactCard}) {

  return (
    <div className='Contact-App'>
        <div className='CA-Primary'>
          <div className='CA-P-Profile'>
            <div className='CA-PP-Inner'>
              <ContactAppHeader picture={picture} fullName={fullName}/>
              <ContactAppBody IoIosDocument getContactCard={getContactCard}/>
            </div>
          </div>
        </div>
        <FooterBar linkBarList={LinkBarProps.inputBarLinks} handleLinkClick={LinkBarProps.changeStateTo} exclude={stateName}/>
    </div>
  )
}

export default ContactApp