import axios from "axios";

const BACKEND_IP = 'https://kylebostelman.com/'
const API_PATH = 'BigAsk/'
const API_URL = BACKEND_IP + API_PATH;

class ApiCalls {
//  Type: GET
//  Returns: { String : String , ...}
//  @Description: QUESTION LIST
    getQuestionList() {
        return axios.get( `${API_URL}questionList`);
    }

//  Type: GET
//  Returns: { myName : String , myFullName : String }
//  @Description: NAME OBJECT
    getYourName() {
        return axios.get( `${API_URL}yourName`);
    }

//  Type: GET
//  Returns: { phoneNumber : String , email : String }
//  @Description: CONTACT INFORMATION
    getContact() {
        return axios.get( `${API_URL}contact`);
    }

//  Type: GET
//  Returns: .vcf File
//  @Description: CONTACT CARD
    getContactCard() {
        return axios.get( `${API_URL}contactCard`);
    }

//  Type: GET
//  Returns: nested object
//  @Description: RESUME OBJECT
    getResume() {
        return axios.get( `${API_URL}resume`);
    }
}

export default new ApiCalls()