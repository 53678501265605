
import { useState, useEffect } from 'react';
import './MessageApp.css'
import MessageAppHeader from './MessageAppHeader/MessageAppHeader';
import MessageAppInput from './MessageAppInput/MessageAppInput';
import MessageAppPrimary from './MessageAppPrimary/MessageAppPrimary';
import MessageAppSelection from './MessageAppSelection/MessageAppSelection';


function MessageApp({stateName, picture, fullName, questionList, introChatList, revisitChatList , NamedRevisitChatList, alreadyVisited, inputState, changeStateTo, nameUser, usersName, inputBarProps, storedMessageList, setStoredMessageList }) {

    // State changes in this component
    const backButtonStateChange = () => {
        changeStateTo('Home');
    };

    const profilePictureStateChange = () => {
        changeStateTo('Contact');
    };

    const contactUsStateChange = () => {
        sendToNextState(changeStateTo, 'Contact');
    };

    const resumeStateChange = () => {
        sendToNextState(changeStateTo, 'Resume');
    };

    const waitTimeToStateChange = "4000"

    const sendToNextState = (changeToState, stateName) => {
        setTimeout(() => {
            changeToState(stateName);
        }, waitTimeToStateChange)
    };


    // Formatting For this component
    const headerHeight = '80px';
    const inputHeight = '100px';



    /* STATE
    Type - Text Input
    Select - Question Selection
    */
    const [ inputType, setInputType ] = useState('Type')
    const [ readyForInput, setReadyForInput ] =useState(false);

    const determineInputState = () => {
        if(inputState === 'NamedRevisit'){
            setInputType('Select');
        }
        else{
            setInputType('Type');
        }
    }

  
    const [openInputWindow, setOpenInputWindow] = useState(false);
    const [inputQuestion, setInputQuestion] = useState("");
    const [messageList, setMessageList] = useState([]);
    const [loadingNextMessage, setLoadingNextMessage] = useState(false);

    const getMessageList = () => {
        setMessageList(storedMessageList);
        return storedMessageList.length > 0;
    }

    const messageTimeoutDuration = "1000";

    const createNewMessage = (text, user) => {
        const newMessage = {
          user: user,
          text: text,
        }
        let tempMessageList = messageList;
        tempMessageList.push(newMessage);
    
        setMessageList(tempMessageList);
        localStorage.setItem("Message-List",messageList);

        if(text === "How can I get ahold of you?"){
            const contactMessages = [
                "I'm excited to hear from you!",
                "Redirecting you to my contact information shortly."
            ]
            contactUsStateChange();
        }

        if(text === "Can I see your resume?"){
            resumeStateChange();
        }

        setStoredMessageList(messageList);
    }

    const toggleInputWindow = () => {
        setOpenInputWindow(!openInputWindow);
    }

    const awaitResponse = (inputQuestion) => {
        setTimeout(() => {
            setLoadingNextMessage(false);
            createNewMessage(questionList[inputQuestion],false);
          }, messageTimeoutDuration)
    }

    const submitQuestion = (inputQuestion) => {
        if(inputQuestion != ""){
            createNewMessage(inputQuestion, true);
            setLoadingNextMessage(true);
            setInputQuestion("");
            awaitResponse(inputQuestion);
        }
    }

    const nameTheUser = (name) => {
        nameUser(name);
        setInputType('Select');
        const greetingMessages = [
            "Hey, " + name,
            "It's a pleasure to meet you!",
            "Feel free to ask me a question or use a link below to navigate to another section of the application."
        ];

        createNewMessage(name,true);
        messageManager(greetingMessages);
        
        
    }

    function messageCreator(chatArray, index, user) {
        return new Promise( () => {
          setTimeout(() => {
            setLoadingNextMessage(false);
            createNewMessage(chatArray[index], user);
            setTimeout(() => {
                messageManager(chatArray,index + 1)
              }, 100);
          }, messageTimeoutDuration);
        });
      }

    // Manages Initial Messages and when user input is allowed
    const messageManager = async (chatArray, index = 0, user = false) => {
        setReadyForInput(false);
        if(index < chatArray.length){       // Send Initial Messages
            setLoadingNextMessage(true);
            const result = await messageCreator(chatArray, index, user)
        }
        else{
            setReadyForInput(true);
        }
        
    };

    // Sets initial chat messages depending on whether the user has visited before
    useEffect(() => {
        determineInputState();
        if(getMessageList()){
            setReadyForInput(true);
        }
        else{
            if(inputState === 'NamedRevisit'){
                messageManager(NamedRevisitChatList);
            }
            else if(inputState === 'Revisit'){
                messageManager(revisitChatList);
            }
            else{
                messageManager(introChatList);
            }
        }

        return () => {};
    },[]);


  return (
    <div className='Message-App-Frame'>
        <MessageAppHeader height={headerHeight} picture={picture} fullName={fullName} backButtonStateChange={backButtonStateChange} profilePictureStateChange={profilePictureStateChange}/>
        <MessageAppPrimary messageList={messageList} loadingNextMessage={loadingNextMessage}/>
        {   openInputWindow &&
            <MessageAppSelection readyForInput={readyForInput} questionList={questionList} setInputQuestion={setInputQuestion} toggleInputWindow={toggleInputWindow} inputType={inputType}/>
        }
        <MessageAppInput stateName={stateName} height={inputHeight} toggleInputWindow={toggleInputWindow} inputQuestion={inputQuestion} submitQuestion={submitQuestion} inputType={inputType} nameTheUser={nameTheUser} inputBarProps={inputBarProps}/>
    </div>
  )
}


export default MessageApp