import './ResumeApp.css'
import FooterBar from '../FooterBar/FooterBar';
import { useEffect, useState } from 'react'; 

import Cornerstone from '../ref/Cornerstone.png'
import Kyson from '../ref/Kyson.png'
import KellerWilliams from '../ref/KW.png'
import Delphi from '../ref/Delphi.png'
import Targamite from '../ref/Targamite.png'
import KylesTiles from '../ref/KylesTiles.png'
import Purdue from '../ref/Purdue.png'


import { AiFillCode, AiOutlineAreaChart } from "react-icons/ai";
import { GiElectricalResistance, GiHouse } from "react-icons/gi";
import { FaDraftingCompass, FaHammer, FaThumbsDown, FaPuzzlePiece } from "react-icons/fa";
import { SiWechat } from "react-icons/si";
import { IoIosArrowUp, IoMdArrowDropright } from "react-icons/io";




function ResumeApp({stateName, LinkBarProps, resumeObject, isMobile}) {

  const HEADER_COLLAPSED_HEIGHT = 60;
  const HEADER_EXPANDED_HEIGHT = 280;


  const [ scrollPosition, setScrollPosition ] = useState(0);
  const [ resumeLabel, setResumeLabel] = useState("Click on a year to show more details.");

  const [ yearPanelObject, setYearPanelObject ] = useState({});

  const [ skillsLabel, setSkillsLabel ] = useState("Proficiency List");

  const [ YearPanelDisplayed, setYearPanelDisplayed ] = useState(false); // Turn to False after Test

  const componentStyling = {
    "--HeaderHeight": `${HEADER_EXPANDED_HEIGHT}px`,
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  let resumeLabelCounter = 0;
  const resumeLabels = [
    "Click on a year to show more details.",
  ]
  isMobile
  ? resumeLabels.push("Scroll to reveal more years.")
  : resumeLabels.push("Shift + scroll wheel for more years.");

  const handleResumeLabelUpdate = () => {
    if(resumeLabelCounter + 1 >= resumeLabels.length) resumeLabelCounter = 0;
    else resumeLabelCounter = resumeLabelCounter + 1

    setResumeLabel(resumeLabels[resumeLabelCounter]);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    // const resumeLabelInterval = setInterval(() => {
    //   handleResumeLabelUpdate();
    // }, 10000);

    return () => {
      // clearInterval(resumeLabelInterval);
      window.removeEventListener('scroll', handleScroll);
  };
  }, []);

  const educationAndWork = resumeObject.educationWork;
  const summary = resumeObject.summary;
  const finalSection = resumeObject.finalSection;

  const handleYearCompClose = () => {
    setYearPanelDisplayed(false);
  };

  const handleYearCompClick = (yearObject) => {
    setYearPanelObject(yearObject);
    setYearPanelDisplayed(true); 
  };

  const YearComponent = (yearObject) => {
    return (
      <div className='RA-Year-Component' onClick={e => handleYearCompClick(yearObject)}>
        <div className='RA-Year-Label'>
          <label>{yearObject.year}</label>
        </div>
        
          {
            Object.keys(yearObject.focus).map((key,id) => {
              const RenderIcon = yearObject.focus[key];
              if(id < 3){
                return(
                <div className='RA-Year-Icon-Conatiner' key={id}>
                  <RenderIcon className='RA-Year-Icon'/>
                </div>
              )
              }
              else{
                return null;
              }
            })
          }
      </div>
    )
  };

  const FocusComponent = ({Icon , title}) => {
    return(
      <div className='RA-Focus-Container'>
        <Icon className='RA-Focus-Icon'/>
        <label className='RA-Focus-Text'>{title}</label>
      </div>
    )
  };

  const YearResumeComponent = ({ employmentArray }) => {
    return (
      <div className='RA-Year-Resume-Component'>
        {
          employmentArray.map((element, key) => {
            return (
              <div key={key} className='RA-Year-Employment-Container'>
                <div className='RA-Year-Employment-Header'>
                  <img src={element.image}  className='RA-Year-Employment-Logo'/>

                  <div className='RA-Year-Employment-Header-Right'>
                    <label className='RA-Year-Employment-Name'>{element.name}</label>
                    <label className='RA-Year-Employment-Title'>{element.title}</label>
                  </div>
                </div>
                <div className='RA-Year-Employment-Body'>
                  {
                    element.description.map((description, key) => {
                      return(
                        <span key={key} className='RA-Year-Employment-Description'>
                          <IoMdArrowDropright />
                          <label>{description}</label>
                        </span>
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }

  const SkillComponent = (skillObject) => {
    const skillbarStyle = {
      '--Skill_Width' : `${skillObject.rank * 10}%`,
    }

    return (
      <div className='RA-Skill-Component'>
        <div className='RA-Skill-Title'>
          <label >{skillObject.title}</label>
        </div>

        <skillObject.icon className='RA-Skill-Icon'/>
        
        <div className='RA-Skill-Bar'>
          <div className='RA-Skill-Bar-Fill' style={skillbarStyle}/>
        </div>

      </div>
    )
  };

  const cornerstoneDirectorEmployment = {
    image: Cornerstone,
    name: 'Cornerstone Detention Products',
    title: 'Director, Software Systems',
    description: [
      "Enterprise Software Stack Implementation.",
      "Configuring a Rest API for office coordination.",
      "CRM, ERP, CMS, CMMS, MMS, & HRIS"
    ]
  };

  const cornerstoneEmployment = {
    image: Cornerstone,
    name: 'Cornerstone Detention Products',
    title: 'Software Engineer',
    description: [
      "Developing a proprietary file & office management system in the browser.",
      "Configuring a Rest API for office coordination.",
      "PLC programming and system design."
    ]
  };

  const kysonEmployment = {
    image: Kyson,
    name: 'Kyson Designs',
    title: 'Design Engineer',
    description: [
      "Design and engineer subdivisions and residential real estate.",
      "Design and draft exhibitry for museums and luxury brands.",
      "Owner & Principal Engineer."
    ]
  };

  const kwEmployment = {
    image: KellerWilliams,
    name: 'Keller Williams',
    title: 'Realtor / Broker',
    description: [
      "Providing the highest level of service to anyone looking to buy, sell, or invest in NC.",
    ]
  };

  const delphiEmployment = {
    image: Delphi,
    name: 'Group Delphi',
    title: 'Design Engineer',
    description: [
      "Designed and drafted museum exhibits and trade shows.",
      "Developed plug-ins and scripts to automate business process."
    ]
  };

  const targamiteEmployment = {
    image: Targamite,
    name: 'Targamite',
    title: 'Software Intern',
    description: [
      "Assisted in the design and programming of sensor networks and tracking algorithms.",
      "Python with heavy networking focus."
    ]
  };

  const kylesTilesEmployment = {
    image: KylesTiles,
    name: 'Kyle\'s Tiles',
    title: 'General Contractor',
    description: [
      "Self-employed General Contractor.",
    ]
  };

  const PurdueSchooling = {
    image: Purdue,
    name: 'Purdue University',
    title: 'Comp Sci Student',
    description: [
      "Upsilon Pi Epsilon Honors President.",
    ]
  };
  
  const resumeList = [
    {
      year: 2023,
      focus:{
        "Enterprise Software Development":AiFillCode,
        "Subdivision Development":GiHouse,
        "Design & Drafting":FaDraftingCompass,
      },
      employment:[
        cornerstoneDirectorEmployment,
        kysonEmployment,
        kwEmployment,
      ]
    },
    {
      year: 2022,
      focus:{
        "Web Development":AiFillCode,
        "Electrical Engineering":GiElectricalResistance,
        "Subdivision Development":GiHouse,
        "Design & Drafting":FaDraftingCompass,
      },
      employment:[
        cornerstoneEmployment,
        kysonEmployment,
        kwEmployment,
      ]
    },
    {
      year: 2021,
      focus:{
        "Subdivision Development":GiHouse,
        "Web Development":AiFillCode,
        "Market Trading":AiOutlineAreaChart,
        "Design & Drafting":FaDraftingCompass,
      },
      employment:[
        kysonEmployment,
        delphiEmployment,
        kwEmployment
      ]
    },
    {
      year: 2020,
      focus:{
        "Market Trading":AiOutlineAreaChart,
        "Design & Drafting":FaDraftingCompass,
        "Web Development":AiFillCode,
        "Real Estate Brokerage":GiHouse,
      },
      employment:[
        kysonEmployment,
        delphiEmployment,
        kwEmployment
      ]
    },
    {
      year: 2019,
      focus:{
        "Design & Drafting":FaDraftingCompass,
        "Real Estate Add Value":GiHouse,
        "Residential Construction":FaHammer,
        "Python Data Science":AiFillCode,
      },
      employment:[
        kysonEmployment,
        delphiEmployment,
        kwEmployment
      ]
    },
    {
      year: 2018,
      focus:{
        "Design & Drafting":FaDraftingCompass,
        "Python Data Science":AiFillCode,
        "Real Estate Add Value":GiHouse,
        "Residential Construction":FaHammer,
      },
      employment:[
        delphiEmployment,
        kwEmployment,
        targamiteEmployment
      ]
    },
    {
      year: 2017,
      focus:{
        "Computer Science":AiFillCode,
        "Design & Drafting":FaDraftingCompass,
        "Residential Construction":FaHammer,
      },
      employment:[
        delphiEmployment,
        targamiteEmployment,
        PurdueSchooling
      ]
    },
    {
      year: 2016,
      focus:{
        "Computer Science":AiFillCode,
        "Residential Construction":FaHammer,
        "Design & Drafting":FaDraftingCompass,
      },
      employment:[
        delphiEmployment,
        PurdueSchooling,
        kylesTilesEmployment
      ]
    },
    {
      year: 2015,
      focus:{
        "Residential Construction":FaHammer,
        "Computer Science":AiFillCode,
        "Design & Drafting":FaDraftingCompass,
      },
      employment:[
        PurdueSchooling,
        kylesTilesEmployment
      ]
    },
    {
      year: 2014,
      focus:{
        "Residential Construction":FaHammer,
        "Computer Science":AiFillCode,
      },
      employment:[
        PurdueSchooling,
        kylesTilesEmployment
      ]
    },
    {
      year: 2013,
      focus:{
        "Residential Construction":FaHammer,
        "Computer Science":AiFillCode,
      },
      employment:[
        PurdueSchooling,
        kylesTilesEmployment
      ]
    },
    {
      year: 2012,
      focus:{
        "Residential Construction":FaHammer,
      },
      employment:[
        kylesTilesEmployment
      ]
    },
  ]

  const skillList = [
    {
      title: "JavaScript",
      icon: AiFillCode,
      rank: 7,
    },
    {
      title: "HTML & CSS",
      icon: AiFillCode,
      rank: 6,
    },
    {
      title: "JSON",
      icon: AiFillCode,
      rank: 7,
    },
    {
      title: "APIs",
      icon: AiFillCode,
      rank: 7,
    },
    {
      title: "Systems",
      icon: AiFillCode,
      rank: 8,
    },
    {
      title: "Networking",
      icon: AiFillCode,
      rank: 7,
    },
    {
      title: "SQL",
      icon: AiFillCode,
      rank: 6,
    },
    {
      title: "AutoCAD",
      icon: FaDraftingCompass,
      rank: 8,
    },
    {
      title: "AD Inventor",
      icon: FaDraftingCompass,
      rank: 9,
    },
    {
      title: "Design",
      icon: FaDraftingCompass,
      rank: 8,
    },
    {
      title: "Real Estate",
      icon: GiHouse,
      rank: 6,
    },
    {
      title: "Development",
      icon: GiHouse,
      rank: 5,
    },
    {
      title: "Fine Carpentry",
      icon: FaHammer,
      rank: 9,
    },
    {
      title: "Construction",
      icon: FaHammer,
      rank: 8,
    },
    {
      title: "Problem Solving",
      icon: FaPuzzlePiece,
      rank: 8,
    },
    {
      title: "Communication",
      icon: SiWechat,
      rank: 7,
    },
    {
      title: "Monotonous Work",
      icon: FaThumbsDown,
      rank: 1,
    },
  ]

  return (
    <div className='Resume-App-Frame' style={componentStyling}>
        <div className='RA-Primary'>
          <div className='RA-Header'>
              <label className='RA-H-Top'>Kyle Bostelman</label>
              <div className='RA-Header-Center'>
                <label className='RA-H-C-Count'>17</label>
                <label className='RA-H-C-Year'>yrs</label>
              </div>
              <label className='RA-H-Bottom'>Work Experience</label>
          </div>


          <div className='RA-Resume'>
            <div className='RA-Resume-Label'>
              <label>{resumeLabel}</label>
            </div>
            <div className='RA-Years-Container'>
              {
                resumeList.map((yearObject, id) => { 
                  return (
                    <YearComponent key={id} {...yearObject}/>
                  )
                })
              }
            </div>
          </div>


          { YearPanelDisplayed
            ?
            <div className='RA-Years-Panel'>
              <div className='RA-Years-Label'>
                <label>{`Summary for ${yearPanelObject.year}`}</label>
                <div className='RA-Years-Panel-Close' onClick={e => handleYearCompClose()}>
                  <IoIosArrowUp className='RA-Years-Panel-Close-Icon'/>
                </div>
              </div>
                {
                  Object.keys(yearPanelObject.focus).map((title, key) => {
                    return(
                      <FocusComponent key={key} Icon={yearPanelObject.focus[title]} title={title} />
                    )
                  })
                }
                <YearResumeComponent employmentArray={yearPanelObject.employment}/>
            </div>
            :
            null
          }

          <div className='RA-Skills'>
            <div className='RA-Skills-Label'>
              <label>{skillsLabel}</label>
            </div>
            
            {
              skillList.map((skillObject, id) => { 
                return (
                  <SkillComponent key={id} {...skillObject}/>
                )
              })
            }
          </div>
        </div>
        <FooterBar linkBarList={LinkBarProps.inputBarLinks} handleLinkClick={LinkBarProps.changeStateTo} exclude={stateName}/>
    </div>
  )
}

export default ResumeApp