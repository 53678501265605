import './ContactAppBody.css';
import { useReducer, useState } from 'react';
import { FaPhoneAlt } from "react-icons/fa";
import { RiMessage2Fill } from "react-icons/ri";
import { IoMdMail, IoIosCopy } from "react-icons/io";



const ContactDownload = ({getContactCard}) => {
    const downloadContact = () => {
        getContactCard();
    }
    return (
        <div className='CA-B-Import Hover-Pointer' onClick={() => downloadContact()}>
            <label className='Hover-Pointer'>Import Contact</label>
        </div>
    )
};

const Phone = () => {
    const openPhone = () => {
        window.open('tel:+14194392829');
    }
    return (
        <div className='CA-B-Contact-Tile Hover-Pointer' onClick={() => openPhone()}>
            <FaPhoneAlt className='CA-BCT-Icon'/>
            <label className='CA-BCT-Label Hover-Pointer'>Call</label>
        </div>
    )
};

const Text = () => {

    const openText = () => {
        window.open('sms:+14194392829');
    }

    return (
        <div className='CA-B-Contact-Tile Hover-Pointer' onClick={() => openText()}>
            <RiMessage2Fill className='CA-BCT-Icon'/>
            <label className='CA-BCT-Label Hover-Pointer'>Text</label>
        </div>
    )
};


const Mail = () => {
    const openMail = () => {
        window.open('mailto:bostelman@live.com');
    }

    return (
        <div className='CA-B-Contact-Tile Hover-Pointer' onClick={() => openMail()}>
            <IoMdMail className='CA-BCT-Icon'/>
            <label className='CA-BCT-Label Hover-Pointer'>E-Mail</label>
        </div>
    )
};

const CopyBar = ({text}) => {
    const messageId = `Copy-Message-${text}`;

    const defaultIconColor = 'rgba(79, 119, 194, 0.9)';
    const clickedIconColor = 'rgba(105, 183, 93, 0.9)';

    const [ iconColor, setIconColor] = useState(defaultIconColor);

    const iconStyle = {'--iconColor' : iconColor};

    const toggleIconColor = ( click ) => {
        if(click) setIconColor(clickedIconColor);
        else setIconColor(defaultIconColor);
    };

    const copyToClipboard = () => {
        toggleIconColor(true);

        setTimeout(() => {
            toggleIconColor(false);
        }, 2000);

        var copyText = document.getElementById(messageId);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
    };

    const onIconClick = () => {
        copyToClipboard();
    };

    return (
        <div className='CA-Copy-Bar'>
            <div className='CA-Copy-Bar-Text'>
                <input type={text} className='CA-CB-Message' id={messageId} onChange={event => {}} value={text} />
            </div>
            <div className='CA-Copy-Bar-Button' onClick={element => onIconClick()}>
                <IoIosCopy style={iconStyle} className='CA-Copy-Bar-Button-Icon'/>
            </div>
        </div>
    )
};

const copyBarFields = {
    "Phone" : "(419) 439-2829",
    "Email" : "Bostelman@live.com",
};

function ContactAppBody({getContactCard}) {
  return (
<div className='CA-Body'>
        <div className='CA-Link-Layer'>
            <Phone />
            <Text />
            <Mail />
        </div>
        <ContactDownload getContactCard={getContactCard} />
    
        <div className='CA-Text-Layer'>
            {
                Object.keys(copyBarFields).map((key, id) => {
                    return (
                        <CopyBar key={id} text={copyBarFields[key]} />
                    )
                })
            }
        </div>
    </div>
  )
};

export default ContactAppBody;