import { useState, useEffect } from 'react';
import './MessageAppInput.css'
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import FooterBar from '../../FooterBar/FooterBar';

import Typewriter from 'typewriter-effect';


function MessageAppInput( { stateName, height, toggleInputWindow, inputQuestion, submitQuestion, inputType, nameTheUser , inputBarProps }) {
  const [ name, setName ] = useState("")
  const [ initialQuestionAsked, setInitialQuestion ] = useState(false);
  const [ readyForInput, setReady ] = useState(false);

  const [sendBrightness, setSendBrightness] = useState(0.4);

  const setBrightnessHigh = () => {
    setSendBrightness(1.4);
  }

  const setBrightnessLow = () => {
    setSendBrightness(0.4);
  }

  useEffect(() => {
  if(name != ""){
      setBrightnessHigh();
    }
    else{
      setBrightnessLow();
    }
  },[name]);

  useEffect(() => {
    if(inputQuestion != ""){
      setBrightnessHigh();
    }
    else{
      setBrightnessLow();
    }
  },[inputQuestion]);

  const MessageAppInputCSS = {
      '--componentHeight': height,
      '--brightness': sendBrightness
  };

  const onInputClick = () => {
    toggleInputWindow();
  }

  const onSendInputClick = () => {
    if(inputType === 'Type'){
      nameTheUser(name);
    }
    else{
      if(!initialQuestionAsked) setInitialQuestion(true);
      submitQuestion(inputQuestion);
    }
  }

  const checkKeySubmit = (key) => {
    if(key === 'Enter') onSendInputClick();
  }

  return (
    <div className='Message-App-Input' style={MessageAppInputCSS}>
      <div className='MAI-Input-Bar' >
        <div className='MAI-IB-Input-Window'>
          { inputType === 'Select' ?
            <div className='MAI-IB-IW IW-Select' onClick={event => {onInputClick(event)}}>
              { 
                inputQuestion === "" && !initialQuestionAsked
                ? <Typewriter
                    onInit={(typewriter) => {

                    typewriter.typeString("")

                    .pauseFor(3000)

                    typewriter.typeString("Click here to ask a question")

                    .start();
                    }}
                  />
                : inputQuestion
              }
            </div>
            :
            <input type={'text'} className='MAI-IB-IW IW-Text' placeholder='Enter your first name here.' onKeyDown={event => {checkKeySubmit(event.key)}} onChange={event => {setName(event.target.value)}} />

          }

          <div className='MAI-IB-IW-Button' onClick={event => {onSendInputClick(event)}}>
            <BsFillArrowUpCircleFill className='MAI-IB-IW-B-Symbol'/>
          </div>
        </div>
      </div>

      <FooterBar linkBarList={inputBarProps.inputBarLinks} handleLinkClick={inputBarProps.changeStateTo} exclude={stateName}/>

    </div>
    
  )
}

export default MessageAppInput