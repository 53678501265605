import './ContactAppHeader.css';


const CenterFrame = ({ picture, fullName }) => {
    return (
        <>
            <div className='CA-Profile-Pic-Frame'>
                <img src={picture} alt="Headshot" className='CA-Profile-Pic'/>
            </div>
            <div className='CA-Profile-Name-Frame'>
                {fullName}
            </div>
        </>
    );
};




function ContactAppHeader( {  picture, fullName } ) {
    return (
        <div className='Contact-App-Header'>
            <CenterFrame picture={picture} fullName={fullName} />
        </div>
    );
}

export default ContactAppHeader