import './AppHomePrimary.css';
import { useEffect, useState } from 'react';

import AppHomeFolder from './AppHomeFolder/AppHomeFolder';
import LinkBar from '../../LinkBar/LinkBar';

import OutsideAlerter from '../../helperFunctions/useOutsideAlerter';

function AppHomePrimary({LinkBarProps, AppHomeFolderList}) {
  const [ folderOpen, setFolderOpen ] = useState(false);
  const [ selectedFolder, setSelectedFolder ] = useState("");
  const [ iconList, setIconList ] = useState([]);
  

  const [ blurValue, setBlurValue ] = useState('0px');

  const newLinkBarProps = {...LinkBarProps, callingStateName:'Home'};
  
  const closeFolder = () => {
    setFolderOpen(false);
    setSelectedFolder("");
  }

  const openFolder = (folderName, iconList) => {
    setFolderOpen(true);
    setSelectedFolder(folderName);
    setIconList(iconList);
  }

  useEffect(() => {
    folderOpen 
      ? setBlurValue('3px')
      : setBlurValue('0px')
  },[folderOpen]);

  const handleIconClick = () => {
    console.log("Clicked on icon")
  }

  const [colorOne, setColorOne ] = useState('0');
  const [colorTwo, setColorTwo ] = useState('0');
  const [colorThree, setColorThree ] = useState('0');
  const [ alphaOne, setAlphaOne ] = useState('0');

  const folderCssProps = []
  folderCssProps['--numberOne'] = colorOne;
  folderCssProps['--numberTwo'] = colorTwo;
  folderCssProps['--numberThree'] = colorThree;
  folderCssProps['--alphaOne'] = alphaOne;
  folderCssProps['--blurValue'] = blurValue;

  useEffect(() => {
    const borderTimer = setInterval(() => {
      changeBorderColor();
    }, 500);
    return () => {clearInterval(borderTimer)}
  },[])

  function randomNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const changeBorderColor = () => {
    setColorOne(String(randomNumberInRange(0,255)))
    setColorTwo(String(randomNumberInRange(0,255)))
    setColorThree(String(randomNumberInRange(0,255)))
    setAlphaOne(String(randomNumberInRange(0.2,0.3)))
  }

  return (
    <>
      <div className='AH-Primary'>
        <div className='AH-Center-Container' >
          {
            Object.keys(AppHomeFolderList).map((name, id) => {
              return(
                <AppHomeFolder key={id} folderName={name} iconList={AppHomeFolderList[name]} openFolder={openFolder}/>
              )
            })
          }

          { folderOpen &&
            <div className='AH-C-Open-Folder-Container' onClick={event => closeFolder}>
              <label className='AH-C-Open-Folder-Label' style={folderCssProps}>{selectedFolder}</label>
              <OutsideAlerter closeFolder={closeFolder}>
                <div className='AH-C-Open-Folder' onClick={event => handleIconClick} style={folderCssProps}>
                  {
                    iconList.map((element)=>{
                      return(
                        <div className='AH-C-Open-Folder-Icon Hover-Pointer' onClick={event => element.click()}>
                          <img src={element.icon} className='AH-C-Open-Folder-Icon-Image'/>
                          <label className='AH-C-Open-Folder-Icon-Label'>{element.name}</label>
                      </div>
                      )
                    })
                  }
                </div>
              </OutsideAlerter>
            </div>
          }
        </div>
        <LinkBar {...newLinkBarProps}/>
      </div>

 
    </>

  )
};

export default AppHomePrimary

