import './MessageAppPrimary.css';


const LoadingNextMessage = () => {
  return(
    <div className='MA-P-Loading'>
      <div className='MA-P-L-Bubble Bubble1'/>
      <div className='MA-P-L-Bubble Bubble2'/>
      <div className='MA-P-L-Bubble Bubble3'/>
    </div>
  )
}

function MessageAppPrimary( { messageList,loadingNextMessage } ) {  

  return (
    <div className='Message-App-Primary'>
      { messageList &&
        Object.entries(messageList).map((messageObject, id) => {
          if(messageObject[1].user){
            return(
              <div className='MA-P-User-Message' key={messageObject[0]}>
                {messageObject[1].text}
              </div>
            );
          }
          else{
            return(
              <div className='MA-P-Web-Message' key={messageObject[0]}>
                {messageObject[1].text}
              </div>
            );
          }
        })
      }

      { loadingNextMessage  &&
        <LoadingNextMessage />
      }
    </div>
  )
}

export default MessageAppPrimary