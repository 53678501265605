import './FooterBar.css';

function FooterBar({linkBarList, componentStyling, handleLinkClick, exclude}) {
  return (
    <div className='MAI-Link-Bar' style={componentStyling}>
    {
      Object.keys(linkBarList).map((name, id) => {
        const LinkSymbol = linkBarList[name]
        if(name === exclude) return null
        return(
          <div className='MAI-LB-Button Hover-Pointer' key={id} onClick={event => {handleLinkClick(name)}}>
            <LinkSymbol key={id} className='MAI-LB-B-Symbol'/>
          </div>
        )
      })
    }
</div>
  )
}

export default FooterBar