import './HomeApp.css'

import AppHomePrimary from './AppHomePrimary/AppHomePrimary'

function HomeApp({HomeAppPrimaryProps}) {

  return (
    <div className='Home-App-Frame'>
        <AppHomePrimary {...HomeAppPrimaryProps} />
    </div>
  )
}

export default HomeApp