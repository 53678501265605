import { useState , useEffect } from 'react';

// State Manager 
import { StateContext, StateProvider } from './StateContext'

import Mobile from './Mobile';
import mobile from './ref/mobile.png'

// Formatting
import './App.css'


function App() {
  const originalMobileWidth = 300;
  const originalMobileHeight = 600;

  const [isMobile, setIsMobile] = useState(false);
  const [ mobileWidth, setMobileWidth ] = useState(String(originalMobileWidth+40)+'px');
  const [ mobileHeight, setMobileHeight ] = useState(String(originalMobileHeight+85)+'px');
  const [ mobileDisplayWidth, setMobileDisplayWidth ] = useState(String(originalMobileWidth)+'px');
  const [ mobileDisplayHeight, setMobileDisplayHeight ] = useState(String(originalMobileHeight)+'px');

  const mobileDims = {
    'width' : mobileWidth,
    'height' : mobileHeight
  }
 
  //choose the screen size 
  const checkMobile = () => {

    if (window.innerWidth < 720 || window.innerHeight < 500) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    checkMobile()
    window.addEventListener("resize", checkMobile)
  },[])

  const appStyling = {
    '--mobileHeight': mobileHeight,
    '--mobileWidth' : mobileWidth,
    '--mobileDisplayHeight' : mobileDisplayHeight,
    '--mobileDisplayWidth' : mobileDisplayWidth,
    '--windowHeight' : window.innerHeight,
    '--windowWidth' : window.innerWidth,
    '--phoneBackground' : mobile,

  }

  // Storage of Users Name and Whether they have vistied this site already    -*- LocalStorageUsed -*-
  const [ alreadyVisited , setAlreadyVisited ] = useState(false);
  const [ usersName , setUsersName ] = useState("");

    // 'Intro' : Introduction,
    // 'Home' : HomeApp,
    // 'Message' : MessageApp,
    // 'Photo' : PhotoApp,
    // 'Contact' : ContactApp,
    // 'Resume' : ResumeApp,
  const [ state , setState ] = useState('Intro');

  
  // Intro - If alreadyVisited & Usersname is not defined
  // Revisit - If alreadyVisited, but usersName is not defined
  // NamedRevisit - If alreadyVisited & UsersName is defined

  const [ inputState, setInputState ] = useState(null);

  const markVisited = () => {     // Function to mark in localStorage that user has visited
    localStorage.setItem('previouslyVisited', true);
    setAlreadyVisited(true);
  }

  const nameUser = name => {     // Function to name the user in localStorage
      localStorage.setItem('usersName', name);
      setUsersName(name);
      setInputState('NamedRevisit');
  }

  const resetWebApplication = () => {
    localStorage.removeItem('previouslyVisited');
    localStorage.removeItem('usersName');
  }


  const startingState = 'Intro';
  const startingStateVisited = 'Intro';

  const changeStateTo = (nextState) => {
    setState(nextState);
  };
  
  useEffect(() => {
    if(localStorage.getItem('previouslyVisited')){  // Check localStorage for previously visited boolean.
      setInputState('Revisit');
      setState(startingStateVisited)

      try{

        if(localStorage.getItem('usersName')) {        // Attempts to set user's name from local storage
          nameUser(localStorage.getItem('usersName'));
          setInputState('NamedRevisit');
        }
      }

      catch{
        setUsersName("");           // Sets to empty string if no name found
        setInputState('Revisit');
        setState(startingStateVisited)
      }
    }

    else{
      setInputState(startingState);
      setState(startingState);
    }
  },[]);

  const mobileProps = {
    usersName:usersName,
    nameUser:nameUser,
    changeStateTo:changeStateTo,
    state:state,
    inputState: inputState,
    markVisited:markVisited,
    alreadyVisited:alreadyVisited,
    isMobile:isMobile,
  }

  if(isMobile){
    return (
      <StateProvider value={null}>
        <div className="App" style={appStyling}>
          <Mobile {...mobileProps}/>
        </div> 
      </StateProvider>

    )
  }
  else{
    return (
      <StateProvider value={null}>
      <div className="App" style={appStyling}>
        <div className='Desktop-Frame'>
          <div className='Desktop-Header'>
            
          </div>

          <div className='Desktop-Center-Container'>
            <div className='Desktop-Main-Window'>
              
            </div>
            <div className='Mobile-Container'>
              <div className='Mobile-Display'>
                <Mobile {...mobileProps}/>
              </div>
            </div>
          </div>

          <div className='Desktop-Footer'>
            
          </div>
        </div>
      </div> 
    </StateProvider>
    )
  }
}

export default App
