import './LinkBar.css';
import { useEffect, useState } from 'react';

function LinkBar( {linkBarLinks, changeStateTo, callingStateName} ) {

  const [colorOne, setColorOne ] = useState('0')
  const [colorTwo, setColorTwo ] = useState('0')
  const [colorThree, setColorThree ] = useState('0')
  const [ alphaOne, setAlphaOne ] = useState('0')

  const handleLinkClick = (linkName) => {
    const changeTo = changeStateTo;
    changeTo(linkName);
  }

  function randomNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const linkBarCssProps = []
  linkBarCssProps['--numberOne'] = colorOne;
  linkBarCssProps['--numberTwo'] = colorTwo;
  linkBarCssProps['--numberThree'] = colorThree;
  linkBarCssProps['--alphaOne'] = alphaOne;

  const changeBorderColor = () => {
    setColorOne(String(randomNumberInRange(0,255)))
    setColorTwo(String(randomNumberInRange(0,255)))
    setColorThree(String(randomNumberInRange(0,255)))
    setAlphaOne(String(randomNumberInRange(0.2,0.3)))
  }

  useEffect(() => {
    setInterval(() => {
      changeBorderColor();
    }, 500);

    return () => {}
  },[])



  return (
    <div className='LinkBar-Container'>
        <div className='LinkBar' style={linkBarCssProps}>
          {
              Object.keys(linkBarLinks).map((iconPath, id) => {
                return(
                  <div className='LB-Button Hover-Pointer' key={id} onClick={event => {handleLinkClick(iconPath)}}>
                    { linkBarLinks &&
                    <img className='LB-B-Symbol' src={linkBarLinks[iconPath]}/>
                    }
                  </div>
                )
              })
            }
        </div>
    </div>
  )
}

export default LinkBar