import './MessageAppSelection.css';

function MessageAppSelection( {readyForInput, questionList, setInputQuestion, toggleInputWindow, inputType } ) {

    const setQuestion = (text) => {
        setInputQuestion(text);
        toggleInputWindow();
    };

    if(readyForInput && inputType === 'Select'){
        return(
            <div className='MA-Selection-Window'>
                {
                Object.keys(questionList).map((text, id) => {
                    return(
                        <div key={id} className="MA-SW-Question" onClick={event => {setQuestion(text)}}>
                        {text}
                        </div>
                    )
                })
                }   
        </div>
        )
    }

    else{
        return null
    }
}

export default MessageAppSelection;